<!-- Settings -->
<template>   
    <div class="card card-custom">
   <div class="card-body p-0">
      <!--begin: Settings-->
      <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
         <!--begin: Settings Body-->
         <div class="row justify-content-center my-10 px-8 px-lg-10">
            <div class="col-xl-12 col-xxl-10 invoicepage">
               <!--begin: Wizard Form-->
               <form class="form" id="kt_form printSection">
                  <!--begin: Wizard Step 1-->
                  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                     <el-row>
                        <el-col :span="24" align="right">
                           <el-dropdown  @command="handleActions($event)" size="medium" split-button class="action_btn">
                              Actions
                              <el-dropdown-menu slot="dropdown" class="action_dropdown">
                                 <el-dropdown-item :command="1" >Print</el-dropdown-item>
                                 <el-dropdown-item :command="2" >Download</el-dropdown-item>
                                 <!-- <el-dropdown-item :command="3" >Send Email</el-dropdown-item> -->
                              </el-dropdown-menu>
                           </el-dropdown>
                        </el-col>
                     </el-row>
                     <el-row>
                        <el-col :span="12" align="left">
                           <span v-loadimage="website.website_logo" ></span>
                        </el-col>
                        <el-col :span="12" align="right">
                           <b >
                           <label class="mt-10">{{website.address?website.address:''}}, {{website.city_details?website.city_details.city_name:''}},</label>
                           <label> {{website.state_details?website.state_details.state_name:''}} - {{website.post_code}}, {{website.country}}.</label><br>
                           <label>Phone: {{website.phone}}</label><br>
                           <label>Fax: {{website.fax}}</label><br>
                           <label>Email: {{website.email_address}}</label>
                           </b>
                           <br>
                        </el-col>
                     </el-row>
                     <hr class="invoice-line">
                     <el-row>
                        <el-col :span="24" align="center">
                           <h2 style="font-family: Arial;text-align: center;"><b>PACKING SLIP</b></h2>
                        </el-col>
                     </el-row>
                     <el-row style="margin-top:5px; margin-bottom:5px;">
                        <el-col :span="12"  align="left">
                           <div>
                              <span><b>PACKING SLIP #: </b></span><span><b>{{view.packing_no}}</b></span>                              
                           </div>
                           <div>
                              <span><b>ORDER ID #: </b></span><span><b>{{view.order.order_number}}</b></span>                              
                           </div>
                           <div><span><b>ORDER DATE : </b></span><span><b>{{view.order.created_at|formatDate('DD-MM-YYYY')}}</b></span></div>
                           <div><span><b>SHIP DATE : </b></span><span><b>{{view.ship_date|formatDate('DD-MM-YYYY')}}</b></span></div>
                        </el-col>
                        <el-col :span="12"  align="right">
                           <div><span><b>DELIVERY DATE : </b></span><span><b>{{view.order.delivery_date|formatDate('DD-MM-YYYY')}}</b></span></div>
                           <div><span><b>CUSTOMER ID : </b></span><span><b> {{view.customer?view.customer.customer_id:''}}</b></span></div>
                           <div><span><b>CUSTOMER P.O# </b></span><span><b> {{view.order?view.order.customer_po:''}}</b></span></div>
                        </el-col>
                     </el-row>
                      <el-row class="add-info">
                        <el-col :span="8" >
                           <div class="billing-adr" style="padding-left:10px;">BILL TO : </div>
                           <div style="padding:3px" v-if="view.customer"><span v-if="view.customer.first_name">{{view.customer.first_name}}</span> <span v-if="view.customer.last_name">{{view.customer.last_name}}</span></div>
                           <div style="padding: 3px;"><span v-if="view.billing_address_order.address_line_2">{{view.billing_address_order.address_line_2}}</span><span v-if="view.billing_address_order.address_line_1"> , Unit No : {{view.billing_address_order.address_line_1 }}</span></div>
                           <div style="padding: 3px;" v-if="view.billing_address_order.city_details">{{view.billing_address_order.city_details.city_name}}</div>
                           <div style="padding: 3px;" v-if="view.billing_address_order.state_details">{{view.billing_address_order.state_details.state_name}}{{view.billing_address_order.post_code ? ' - '+view.billing_address_order.post_code : '' }}</div>
                        </el-col>
                        <el-col :span="8" align="left" :offset="8" v-if="view.shipping_address_order">
                           <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                           <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name==null?'':view.customer.last_name):''}}</div>
                           <div style="padding: 3px;"><span v-if="view.shipping_address_order.address_line_2">{{view.shipping_address_order.address_line_2}}</span><span v-if="view.shipping_address_order.address_line_1"> , Unit No : {{view.shipping_address_order.address_line_1 }}</span></div>
                           <div style="padding: 3px;" v-if="view.shipping_address_order.city_details">{{view.shipping_address_order.city_details.city_name}}</div>
                           <div style="padding: 3px;" v-if="view.shipping_address_order.state_details">{{view.shipping_address_order.state_details.state_name}}{{view.shipping_address_order.post_code ?' - '+view.shipping_address_order.post_code : '' }}</div>
                        </el-col>
                     </el-row>
                     <div class="row">
                        <div class="col-xl-12 ">
                           <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" style="margin-top:20px;">
                              <tbody role="rowgroup">
                                 <tr class="billing-cl2 text-center invoice-header" >
                                    <th aria-colindex="1" role="cell">
                                       <div >ITEM #</div>
                                    </th>
                                    <th aria-colindex="2" role="cell">
                                       <div >PRODUCT NAME</div>
                                    </th>
                                    <th aria-colindex="2" role="cell">
                                       <div >SHIP VIA</div>
                                    </th>
                                    <th aria-colindex="2" role="cell">
                                       <div >Quantity</div>
                                    </th>
                                    <th aria-colindex="2" role="cell">
                                       <div >Type</div>
                                    </th>                                    
                                   
                                     <th aria-colindex="2" role="cell">
                                       <div >SHIPPED</div>
                                    </th>
                                     <th aria-colindex="2" role="cell">
                                       <div >BACKORDER</div>
                                    </th>
                                 </tr>
                                 <tr class="billing-cl3" v-for="item in view.order_items" :key="item.id">
                                    <td aria-colindex="1" role="cell" class="text-center">
                                       <div class="padding-10">{{item.product.sku}}</div>
                                    </td>
                                    <td aria-colindex="1" role="cell" class="text-center">
                                       <div class="padding-10">{{item.product.name}}</div>
                                    </td>
                                    <td aria-colindex="2" role="cell" class="text-center">
                                       <div class="padding-10">{{view.order.ship_via}}</div>
                                    </td>
                                    <td aria-colindex="1" role="cell" class="text-center">
                                       <div class="padding-10">{{item.quantity}}</div>
                                    </td>
                                    <td aria-colindex="2" role="cell" class="text-center">
                                       <div class="padding-10">{{item.product.unit}}</div>
                                    </td>  
                                     <td aria-colindex="1" role="cell" class="text-center">
                                       <div class="padding-10">{{item.shipped}}</div>
                                    </td>                                   
                                    <td aria-colindex="1" role="cell" class="text-center">
                                       <div class="padding-10">{{item.back_order}}</div>
                                    </td>
                                   
                                 </tr>
                                 <tr class="billing-cl3">
                                    <td aria-colindex="2" role="cell" colspan="7">
                                       <div class="padding-10"><b><u>Other Comments or Special Instructions</u></b></div>
                                        <div class="padding-10" v-if="view.order.delivery_type">
                                          <span><b>DELIVERY TYPE : </b>{{view.order.delivery_type}}</span>
                                       </div>
                                       <div class="padding-10" v-if="view.order.pickup_person">
                                          <span><b>Who is receiving order : </b>{{view.order.pickup_person}}</span>
                                       </div>
                                       <div class="padding-10 mt-1">*We required the Government authorised id to verify the delivery</div>
                                       <div class="padding-10">{{view.comments}}</div>
                                       <div class="padding-10" style="margin-top:40px;"></div>
                                       <div class="padding-10"><b>Total Quantity :</b> {{view.total_quantity}}</div>
                                        <div class="padding-10" v-if="view.total_weight"><b>Total weight :</b> {{view.total_weight}} Kg </div>                                        
                                    </td>                                    
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                     <el-row>
                        <el-col :span="24" align="left">
                           <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                           <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                        </el-col>
                     </el-row>
                  </div>
               </form>
               <!--end: Settings Form-->
            </div>
         </div>
         <!--end: Settings Body-->
      </div>
   </div>
   <!--end: Settings-->
</div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getPackingSlip,downloadPdf} from "@/api/packingslip"
//import SendEmail from '@/components/SendEmail'
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[], 
      website:[], 
      view_type: 'packingslip'
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Order Management", route: "/order/list" },
      { title: "View Order" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
   fetchData() {
      this.loading = true
      getPackingSlip(this.$route.params.packing_id).then(response => {        
        this.view = response.data.data[0]
        this.orderItem = response.data.data[0].product_items;
        this.website = response.data.data[0].order.website_details 
        this.loading = false
      });
    },
     printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    }, 
     handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.packing_id, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'packingslip.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }
    }    
   }
   
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;margin-top:30px;margin-left: 10px;
    }
    @media print {
       
        .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
   
    

}
 
</style>